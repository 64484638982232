import {useEffect, useRef, useState} from 'preact/hooks';

export default QuantitySelector;

/**
 * A component that allows the user to select a quantity
 * It can be configured to have a minimum and maximum value
 * It takes an optional callback `onUpdate` that is called when the value is updated
 * @param props - The props
 * @returns The QuantitySelector component
 */
function QuantitySelector({
  className = 'text-dark-navy-black border-gray',
  value: valueProp = 0,
  min: minProp = 0,
  max: maxProp = Infinity,
  onUpdate,
}: QuantitySelectorProps) {
  const value = Number(valueProp);
  const min = Number(minProp);
  const max = Number(maxProp);

  const inputRef = useRef<HTMLInputElement>(null);

  const [quantity, setQuantity] = useState(value);
  useEffect(() => {
    setQuantity((prev) => (prev !== value ? value : prev));
  }, [value]);

  const handleChangeQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    if (onUpdate) {
      onUpdate(newQuantity);
    }

    // After re-render, dispatch a change event on the input element
    // This notifies the form that the value has changed
    setTimeout(
      () =>
        inputRef.current?.dispatchEvent(new Event('change', {bubbles: true})),
      0
    );
  };

  // Decrease the quantity by 1 if it is greater than the minimum
  // Call the onUpdate callback if it exists
  const decrement = (e: Event) => {
    e.preventDefault();
    if (quantity <= min) return;

    handleChangeQuantity(quantity - 1);
  };

  // Increase the quantity by 1 if it is less than the maximum
  // Call the onUpdate callback if it exists
  const increment = (e: Event) => {
    e.preventDefault();
    if (quantity >= max) return;

    handleChangeQuantity(quantity + 1);
  };

  return (
    <div
      className={`flex h-8 w-24 justify-between rounded-md border  bg-crisp-white text-center text-base font-bold ${className}`}
    >
      <button
        className="fast-transition w-8 rounded-md border-none bg-transparent text-xl outline-none hover:bg-crisp-white-hover active:bg-crisp-white-active disabled:bg-transparent disabled:text-neutral-gray"
        disabled={quantity <= min}
        onClick={decrement}
      >
        -
      </button>
      <span className="grow border-x border-gray text-base leading-8">
        {quantity}
      </span>
      <button
        className="fast-transition w-8 rounded-md border-none bg-transparent text-xl outline-none hover:bg-crisp-white-hover  active:bg-crisp-white-active disabled:bg-transparent disabled:text-neutral-gray"
        onClick={increment}
        disabled={quantity >= max}
      >
        +
      </button>
      <input type="hidden" name="quantity" value={quantity} ref={inputRef} />
    </div>
  );
}

/**
 * The props for the QuantitySelector component
 * @param value - The initial value of the quantity
 * @param min - The minimum value of the quantity
 * @param max - The maximum value of the quantity
 * @param onUpdate - An optional callback that is called when the quantity is updated
 */
type QuantitySelectorProps = {
  className?: string;
  value?: number | string;
  min?: number | string;
  max?: number | string;
  onUpdate?: (quantity: number) => void;
};
